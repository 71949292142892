import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Protocal } from "../../../services/api_function";
import { Link } from "react-router-dom";
const Rank = () => {
  const [api, setApi] = useState([]);
  useEffect(() => {}, []);
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Rank Bonus
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong> Rank</strong>
                    </th>
                    <th></th>
                    <th>
                      <strong>Team Business</strong>
                    </th>

                    <th>
                      <strong> Rank Bonus</strong>
                    </th>
                    {/* <th>
                      <strong> Team Size</strong>
                    </th>
                    <th>
                      <strong>Team business </strong>
                    </th>
                    <th>
                      <strong>Rank bonus</strong>
                    </th> */}
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Bronze</td>
                    <td></td>
                    <td> 500</td>
                    <td>10</td>
                    <td>
                      <Link
                        to="/rank-data?rank=1"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Silver</td>
                    <td></td>
                    <td>1000</td>
                    <td>10</td>

                    <td>
                      <Link
                        to="/rank-data?rank=2"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Gold</td>
                    <td></td>
                    <td> 2500</td>
                    <td>30</td>

                    <td>
                      <Link
                        to="/rank-data?rank=3"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Platinum</td>
                    <td></td>
                    <td> 5000</td>
                    <td>75</td>

                    <td>
                      <Link
                        to="/rank-data?rank=4"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Beryl</td>
                    <td></td>
                    <td> 10,000</td>
                    <td>100</td>

                    <td>
                      <Link
                        to="/rank-data?rank=5"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Sapphire</td>
                    <td></td>
                    <td> 25000</td>
                    <td>300</td>

                    <td>
                      <Link
                        to="/rank-data?rank=6"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Ruby</td>
                    <td></td>
                    <td> 50000</td>
                    <td>500</td>

                    <td>
                      <Link
                        to="/rank-data?rank=7"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Emerald</td>
                    <td></td>
                    <td>1,00,000</td>
                    <td>1000</td>

                    <td>
                      <Link
                        to="/rank-data?rank=8"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Diamond</td>
                    <td></td>
                    <td>2,00,000</td>
                    <td>2,000</td>

                    <td>
                      <Link
                        to="/rank-data?rank=9"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Rank;
